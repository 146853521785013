<template>
  <div style="background: #fff;">
    <div class="main sc-width">
      <div class="bg">
        <img src="@/assets/about/top.png" alt="" />
      </div>
      <div class="top">
        <div class="left">
          <div class="bg2">
            <svg
              width="238"
              height="405"
              viewBox="0 0 238 405"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_dddddd_22_8914)">
                <path
                  d="M45.0929 5.21688C52.8279 0.751059 62.3579 0.751059 70.0929 5.21688L144.686 48.2831C152.421 52.7489 157.186 61.0021 157.186 69.9338V156.066C157.186 164.998 152.421 173.251 144.686 177.717L70.0929 220.783C62.3579 225.249 52.8279 225.249 45.0929 220.783L-29.5 177.717C-37.235 173.251 -42 164.998 -42 156.066V69.9338C-42 61.0021 -37.235 52.7489 -29.5 48.2831L45.0929 5.21688Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_dddddd_22_8914"
                  x="-122"
                  y="0.571135"
                  width="359.186"
                  height="403.561"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.85185" />
                  <feGaussianBlur stdDeviation="1.57407" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_8914"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="8.14815" />
                  <feGaussianBlur stdDeviation="3.25926" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0117778 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_22_8914"
                    result="effect2_dropShadow_22_8914"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="20" />
                  <feGaussianBlur stdDeviation="6.5" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.015 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_22_8914"
                    result="effect3_dropShadow_22_8914"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="38.5185" />
                  <feGaussianBlur stdDeviation="12.7407" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0182222 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect3_dropShadow_22_8914"
                    result="effect4_dropShadow_22_8914"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="64.8148" />
                  <feGaussianBlur stdDeviation="23.4259" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0227778 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect4_dropShadow_22_8914"
                    result="effect5_dropShadow_22_8914"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="100" />
                  <feGaussianBlur stdDeviation="40" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect5_dropShadow_22_8914"
                    result="effect6_dropShadow_22_8914"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect6_dropShadow_22_8914"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>

          <div class="title">
            Travel less<br />
            Connect wise.
          </div>
          <div class="sub-title">
            The innovative ingredient sourcing platform
          </div>
          <router-link :to="`/`">
            <div class="btn search-button">Our Process</div>
          </router-link>
        </div>
        <div class="right">
          <div class="title">Get Started for Free</div>
          <div class="email">
            <input
              type="text"
              placeholder="Email Address"
              autocomplete="off"
              name="email1"
              v-model="email"
            />
          </div>
          <div class="password">
            <input
              type="password"
              placeholder="Password"
              name="password1"
              autocomplete="new-password"
              v-model="password"
            />
          </div>
          <div class="started search-button" @click="showLogin">
            GET STARTED
          </div>
        </div>
      </div>

      <div class="business">
        <div class="title">
          Trusted By Over 100+ Startups and freelance business
        </div>
        <div class="items">
          <div class="item">
            <img
              src="https://connexima.s3-us-west-1.amazonaws.com/images/ii6nEs3JiaSQ8NN26U4pAp.png"
              alt=""
            />
          </div>
          <div class="item">
            <img
              src="https://connexima.s3-us-west-1.amazonaws.com/documents/jLTeoQCca2K4sy387yBNtS.jpeg"
              alt=""
            />
          </div>
          <div class="item">
            <img
              src="https://connexima.s3-us-west-1.amazonaws.com/images/3VXKGCQ3thcb1n788o6Fhj.png"
              alt=""
            />
          </div>
          <div class="item">
            <img
              src="https://connexima.s3-us-west-1.amazonaws.com/images/wEhjbDPmNAhHcYftV93p6m.png"
              alt=""
            />
          </div>
          <div class="item">
            <img
              src="https://connexima.s3-us-west-1.amazonaws.com/images/wkDESKnXB7dFD4CzoQPQbn.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="we">
        <div class="title">Who we are?</div>
        <div class="content">
          <div class="left">
            <div class="img">
<!--              <img src="@/assets/about/we.jpg" alt="" />-->
            </div>
            <div class="img-bottom">
              <svg
                width="650"
                height="91"
                viewBox="0 0 650 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44.7331 30.5568C13.3287 36.3457 1.82584 46.3061 0 50.5626V91H650V39.9212C611.962 25.1651 525.66 -3.41062 484.761 0.335162C433.638 5.01739 419.944 21.618 329.565 35.6647C239.185 49.7113 83.9888 23.3206 44.7331 30.5568Z"
                  fill="#5243C2"
                />
              </svg>
            </div>
            <div class="img-right">
              <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="35" cy="35" r="35" fill="#5243C2" />
                <path
                  d="M52 19.2663L51.2177 24.8892C49.653 24.7577 48.3817 25.0207 47.4038 25.6784C46.4259 26.3032 45.7413 27.2239 45.3502 28.4406C44.9916 29.6243 44.9264 31.0054 45.1546 32.5838H52V46H38.7003V32.5838C38.7003 27.8487 39.7923 24.2644 41.9763 21.8311C44.1604 19.3649 47.5016 18.5099 52 19.2663ZM34.2997 19.2663L33.5173 24.8892C31.9527 24.7577 30.6814 25.0207 29.7035 25.6784C28.7256 26.3032 28.041 27.2239 27.6498 28.4406C27.2913 29.6243 27.2261 31.0054 27.4543 32.5838H34.2997V46H21V32.5838C21 27.8487 22.092 24.2644 24.276 21.8311C26.46 19.3649 29.8013 18.5099 34.2997 19.2663Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div class="right">
            <div class="word-top">
              <svg
                width="37"
                height="27"
                viewBox="0 0 37 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36.5415 0.26625L35.6193 5.88921C33.7749 5.75768 32.2764 6.02075 31.1236 6.6784C29.9709 7.30318 29.164 8.2239 28.7029 9.44056C28.2802 10.6243 28.2034 12.0054 28.4724 13.5838H36.5415V27H20.8644V13.5838C20.8644 8.84867 22.1516 5.26444 24.726 2.83111C27.3004 0.3649 31.2389 -0.490055 36.5415 0.26625ZM15.6771 0.26625L14.7549 5.88921C12.9105 5.75768 11.412 6.02075 10.2593 6.6784C9.10655 7.30318 8.29964 8.2239 7.83854 9.44056C7.41588 10.6243 7.33903 12.0054 7.608 13.5838H15.6771V27H0V13.5838C0 8.84867 1.28721 5.26444 3.86164 2.83111C6.43606 0.3649 10.3745 -0.490055 15.6771 0.26625Z"
                  fill="#FF7F5C"
                />
              </svg>
            </div>
            <div class="title">We Help To Get Solutions</div>
            <div class="word">
              Designed to bridge and maximize the connection between buyers and
              sellers online, Connexima sets itself apart with its extensive
              ingredient database MaxData, sourcing channel MaxSource, quotation
              channel MaxSell, accurate matchmaking, built-in automation, and
              other smart functions. <br />
              <br />
              Connexima is a transformative platform designed for the future. We
              are changing the nutraceutical industry, one connection at a time.
            </div>
          </div>
        </div>
      </div>
      <div class="companies">
        <div class="title">MaxTrade</div>
        <div class="sub-title">Source and sell products with ease.</div>
        <div class="items">
          <div class="item">
            <div class="icon">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="64" height="64" rx="20" fill="#E8F2FF" />
                <rect width="64" height="64" rx="20" fill="#F8F9FD" />
                <path
                  d="M41.534 28.9071C41.534 23.6571 37.2504 19.3735 32.0004 19.3735C26.7504 19.3735 22.4668 23.6571 22.4668 28.9071C22.4668 29.6635 22.5505 30.4198 22.7605 31.1744C23.0131 32.3918 23.4741 33.1481 24.1041 34.1981C24.2305 34.4508 24.3978 34.7018 24.5668 34.9954C24.7768 35.3318 24.9442 35.6681 25.1541 35.9618C25.9941 37.3481 26.4978 38.1454 26.4978 39.7844V43.7318C26.4978 44.7391 27.2115 45.5381 28.1778 45.7054C28.5978 47.8481 29.8578 49.1918 32.0005 49.1918C34.1431 49.1918 35.4441 47.8481 35.8231 45.7054C36.7895 45.5381 37.5031 44.6981 37.5031 43.7318V39.7844C37.5031 38.1471 38.0068 37.3071 38.8468 35.9618C39.0141 35.6681 39.2241 35.3318 39.4341 34.9954C39.6015 34.7018 39.7705 34.4491 39.8968 34.1981C40.5268 33.1481 40.9895 32.3918 41.2405 31.1744C41.4505 30.4197 41.5341 29.6634 41.5341 28.9071H41.534ZM35.864 40.7935H28.2204V39.8272C28.2204 39.6172 28.2204 39.4499 28.1777 39.2809H35.8641C35.8641 39.4482 35.8215 39.6172 35.8215 39.8272L35.8231 40.7935H35.864ZM35.4866 44.1125H28.5138C28.3038 44.1125 28.1774 43.9452 28.1774 43.7762V42.4735H35.821V43.7762C35.8637 43.9435 35.6963 44.1125 35.4863 44.1125H35.4866ZM32.0003 47.5562C31.5376 47.5562 30.3629 47.5562 29.9003 45.7925H34.1431C33.6804 47.5562 32.4631 47.5562 32.0004 47.5562H32.0003ZM39.6031 30.8397C39.3931 31.7634 39.0568 32.3934 38.4694 33.3597C38.3021 33.6124 38.1758 33.8634 38.0068 34.1571C37.7968 34.4934 37.6294 34.8297 37.4604 35.0807C36.9568 35.9207 36.4941 36.6771 36.2004 37.5581H27.884C27.5904 36.6754 27.1704 35.9207 26.624 35.0807C26.4567 34.7871 26.2467 34.4934 26.0777 34.1571C25.9103 33.8634 25.7414 33.5697 25.615 33.3597C25.0277 32.3524 24.6914 31.7634 24.4814 30.7971C24.314 30.1671 24.2287 29.5371 24.2287 28.9071C24.2287 24.5807 27.756 21.0535 32.0823 21.0535C36.4087 21.0535 39.9359 24.5808 39.9359 28.9071C39.8538 29.5371 39.7702 30.1671 39.6028 30.8397H39.6031Z"
                  fill="#4F46BA"
                />
                <path
                  d="M49.3039 28.0671H45.8175C45.3549 28.0671 44.9775 28.4445 44.9775 28.9071C44.9775 29.3698 45.3549 29.7471 45.8175 29.7471H49.3039C49.7665 29.7471 50.1439 29.3698 50.1439 28.9071C50.1439 28.4461 49.7665 28.0671 49.3039 28.0671Z"
                  fill="#4F46BA"
                />
                <path
                  d="M41.7436 20.0035C41.9536 20.0035 42.1636 19.9199 42.3309 19.7509L44.8082 17.2736C45.1446 16.9372 45.1446 16.4336 44.8082 16.0972C44.4719 15.7609 43.9683 15.7609 43.6319 16.0972L41.1546 18.5745C40.8183 18.9109 40.8183 19.4145 41.1546 19.7509C41.3236 19.9199 41.5336 20.0035 41.7436 20.0035H41.7436Z"
                  fill="#4F46BA"
                />
                <path
                  d="M32.0002 15.93C32.4628 15.93 32.8402 15.5527 32.8402 15.09V11.6037C32.8402 11.141 32.4628 10.7637 32.0002 10.7637C31.5375 10.7637 31.1602 11.141 31.1602 11.6037V15.09C31.1602 15.5527 31.5375 15.93 32.0002 15.93Z"
                  fill="#4F46BA"
                />
                <path
                  d="M21.6673 19.7528C21.8346 19.9202 22.0446 20.0055 22.2546 20.0055C22.4646 20.0055 22.6746 19.9218 22.8419 19.7528C23.1783 19.4165 23.1783 18.9128 22.8419 18.5765L20.3646 16.0992C20.0283 15.7628 19.5246 15.7628 19.1883 16.0992C18.852 16.4355 18.852 16.9392 19.1883 17.2755L21.6673 19.7528Z"
                  fill="#4F46BA"
                />
                <path
                  d="M18.2238 28.0671H14.7375C14.2748 28.0671 13.8975 28.4445 13.8975 28.9071C13.8975 29.3698 14.2748 29.7471 14.7375 29.7471H18.2238C18.6865 29.7471 19.0638 29.3698 19.0638 28.9071C19.0638 28.4461 18.6865 28.0671 18.2238 28.0671Z"
                  fill="#4F46BA"
                />
                <path
                  d="M21.6676 38.0635L19.1902 40.5409C18.8539 40.8772 18.8539 41.3809 19.1902 41.7172C19.3576 41.8845 19.5676 41.9698 19.7776 41.9698C19.9875 41.9698 20.1976 41.8862 20.3649 41.7172L22.8422 39.2399C23.1785 38.9035 23.1785 38.3999 22.8422 38.0635C22.5075 37.7272 21.9628 37.7272 21.6675 38.0635H21.6676Z"
                  fill="#4F46BA"
                />
                <path
                  d="M42.3329 38.0635C41.9966 37.7272 41.4929 37.7272 41.1565 38.0635C40.8202 38.3999 40.8202 38.9035 41.1565 39.2399L43.6339 41.7172C43.8012 41.8845 44.0112 41.9698 44.2212 41.9698C44.4312 41.9698 44.6412 41.8862 44.8085 41.7172C45.1449 41.3809 45.1449 40.8772 44.8085 40.5409L42.3329 38.0635Z"
                  fill="#4F46BA"
                />
              </svg>
            </div>
            <div class="name">
              <div class="word">Specify your requests</div>
              <div class="pic">
                1
              </div>
            </div>

            <div class="content">
              Fill in the product information you need as required
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="64" height="64" rx="20" fill="#E8F2FF" />
                <rect width="64" height="64" rx="20" fill="#F8F9FD" />
                <path
                  d="M47.3186 22.1032L41.8966 16.6812C41.7857 16.5687 41.6373 16.5078 41.4794 16.5078C41.3232 16.5078 41.1732 16.5687 41.0622 16.6812L32.4106 25.3312L33.2466 26.1656L41.4794 17.9328L46.0654 22.5204L44.631 23.9548L42.0326 21.3548C41.8013 21.1251 41.4294 21.1251 41.1982 21.3548C40.967 21.5876 40.967 21.9594 41.1982 22.1891L43.7966 24.7891L42.1529 26.4329L40.5122 24.7922C40.281 24.5594 39.9091 24.5594 39.6779 24.7922C39.4466 25.0219 39.4466 25.3954 39.6779 25.6266L41.3185 27.2672L39.6748 28.911L37.0748 26.311C36.8435 26.0798 36.4716 26.0798 36.2404 26.311C36.0092 26.5423 36.0092 26.9141 36.2404 27.1454L38.8404 29.7454L37.8373 30.7485L38.6716 31.5829L47.3232 22.9328C47.5482 22.7078 47.5482 22.3344 47.3186 22.1032L47.3186 22.1032Z"
                  fill="#4F46BA"
                />
                <path
                  d="M29.7514 38.8344L27.153 36.236C26.9217 36.0047 26.5499 36.0047 26.3186 36.236C26.0874 36.4672 26.0874 36.8391 26.3186 37.0703L28.9171 39.6688L27.2733 41.3141L25.6327 39.6734C25.4014 39.4422 25.028 39.4422 24.7967 39.6734C24.5655 39.9047 24.5655 40.2766 24.7967 40.5078L26.4374 42.1484L24.7936 43.7906L22.1952 41.1938C21.9639 40.9609 21.5905 40.9609 21.3593 41.1938C21.128 41.4234 21.128 41.7969 21.3593 42.0281L23.9577 44.6266L22.5233 46.0625L17.9373 41.4733L26.1685 33.2421L25.3342 32.4077L16.6842 41.0561C16.4529 41.2874 16.4529 41.6592 16.6842 41.8905L22.1062 47.3141C22.2171 47.4235 22.3656 47.4875 22.5234 47.4875C22.6796 47.4875 22.8296 47.4234 22.9406 47.3141L31.5906 38.6641L30.7547 37.8297L29.7514 38.8344Z"
                  fill="#4F46BA"
                />
                <path
                  d="M44.6748 39.2657L25.1156 19.7065L23.8094 18.3971C22.8844 17.469 21.3016 17.4128 20.4453 18.2721L18.2718 20.4471C17.3812 21.3378 17.439 22.8471 18.3984 23.808L39.2656 44.6769C39.5375 44.9456 39.9906 45.3925 44.9704 46.5206C45.0735 46.5425 45.1766 46.555 45.2798 46.555C45.6267 46.555 45.9501 46.4237 46.1891 46.1831C46.4954 45.8737 46.6188 45.4222 46.5204 44.9738C45.3923 39.9846 44.9423 39.5345 44.6751 39.2657L44.6748 39.2657ZM19.234 22.9721C18.7324 22.4721 18.6746 21.7127 19.1059 21.2799L21.2778 19.108C21.4637 18.9205 21.7246 18.8174 22.009 18.8174C22.3559 18.8174 22.7074 18.9689 22.9715 19.233L23.8621 20.1237L20.123 23.8627L19.234 22.9721ZM39.1152 41.3253C38.909 41.2612 38.684 41.3159 38.5308 41.4659C38.3777 41.6175 38.3168 41.8393 38.3746 42.0472L38.4012 42.1425L20.9592 24.6973L24.6998 20.9582L42.0294 38.291C41.8263 38.2379 41.6122 38.291 41.4638 38.4363C41.3106 38.5879 41.2513 38.8097 41.3091 39.0144L41.6169 40.1238L40.5825 39.8082C40.3763 39.7441 40.1513 39.8003 39.9982 39.9519C39.8435 40.1003 39.7841 40.3253 39.8419 40.5331L40.1497 41.6394L39.1152 41.3253ZM40.0996 43.8409L39.8027 42.769L40.8308 43.0847C41.0402 43.1456 41.2714 43.0878 41.4246 42.9331C41.5746 42.7815 41.6339 42.5612 41.5746 42.3565L41.2683 41.2534L42.2996 41.5675C42.5042 41.6316 42.7292 41.5753 42.8839 41.4237C43.037 41.2753 43.098 41.0519 43.0402 40.8456L42.7292 39.7253L43.7636 40.0284L43.8417 40.105C43.9261 40.1894 44.0761 40.4519 44.3074 41.1519L41.1542 44.3081C40.448 44.0722 40.187 43.9253 40.0996 43.8409L40.0996 43.8409ZM45.3512 45.3534C45.3371 45.3659 45.3121 45.3753 45.2824 45.3753L45.2355 45.3675C44.0543 45.1018 43.148 44.8753 42.4402 44.6847L44.6871 42.4378C44.8777 43.144 45.1027 44.0503 45.3714 45.23C45.3793 45.2831 45.3714 45.3316 45.3511 45.3534L45.3512 45.3534Z"
                  fill="#4F46BA"
                />
              </svg>
            </div>
            <div class="name">
              <div class="word">Submit your inquiry</div>
              <div class="pic">
                2
              </div>
            </div>

            <div class="content">
              Submit the form after confirming all the information
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="64" height="64" rx="20" fill="#E8F2FF" />
                <rect width="64" height="64" rx="20" fill="#F8F9FD" />
                <path
                  d="M46.6314 35.1279H44.5798V18.7279C44.5736 17.6623 43.7095 16.8014 42.6439 16.7998H21.3563C20.286 16.8014 19.4204 17.6701 19.4204 18.7404V35.1404H17.3688C16.9266 35.1404 16.5688 35.4983 16.5688 35.9404V45.2592C16.5688 46.3296 17.4345 47.1983 18.5048 47.1999H45.4956C46.5659 47.1983 47.4315 46.3296 47.4315 45.2592V35.928C47.4315 35.7155 47.3487 35.5124 47.1987 35.3624C47.0487 35.2124 46.844 35.128 46.6315 35.128L46.6314 35.1279ZM21.0314 18.7279C21.0314 18.542 21.1814 18.3904 21.3689 18.3873H42.6441C42.83 18.3904 42.98 18.542 42.98 18.7279V35.1279H40.58C40.1847 35.1232 39.8441 35.4092 39.78 35.7998L39.3441 38.492H24.6645L24.2286 35.7998C24.1645 35.4092 23.8255 35.1232 23.4286 35.1279H21.0286L21.0314 18.7279ZM45.8314 45.2483C45.8314 45.4343 45.683 45.5858 45.4955 45.5874H18.5047C18.3172 45.5858 18.1687 45.4343 18.1687 45.2483V36.7279H22.7563L23.2001 39.4201C23.2642 39.8107 23.6032 40.0967 24.0001 40.092H40.0409C40.4362 40.0967 40.7768 39.8108 40.8409 39.4201L41.2768 36.7279H45.8316L45.8314 45.2483Z"
                  fill="#4F46BA"
                />
                <path
                  d="M34.0564 41.8589H29.944C29.5018 41.8589 29.144 42.2183 29.144 42.6589C29.144 43.1011 29.5019 43.4589 29.944 43.4589H34.0564C34.4986 43.4589 34.8564 43.1011 34.8564 42.6589C34.8564 42.2182 34.4986 41.8589 34.0564 41.8589Z"
                  fill="#4F46BA"
                />
                <path
                  d="M29.1564 27.0919L31.2002 24.6591V34.4311C31.2002 34.8733 31.558 35.2311 32.0002 35.2311C32.4424 35.2311 32.8002 34.8733 32.8002 34.4311V24.6591L34.8439 27.0904V27.0919C34.9971 27.2716 35.2205 27.3763 35.4564 27.3763C35.6424 27.3779 35.8236 27.3154 35.9689 27.1997C36.3048 26.9169 36.3501 26.4138 36.0689 26.0763L32.6127 21.9483C32.4548 21.7764 32.233 21.678 32.0002 21.678C31.7674 21.678 31.5455 21.7764 31.3877 21.9483L27.9315 26.0639C27.6721 26.403 27.7252 26.8873 28.0518 27.1639C28.3799 27.4389 28.8674 27.4076 29.1564 27.092L29.1564 27.0919Z"
                  fill="#4F46BA"
                />
              </svg>
            </div>
            <div class="name">
              <div class="word">Connect directly with others</div>
              <div class="pic">
                3
              </div>
            </div>
            <div class="content">
              Other users will quote you after seeing the information
            </div>
          </div>
        </div>
        <router-link :to="`/maxtrade`">
          <div class="button search-button">Try it now!</div>
        </router-link>
      </div>
      <div class="bg1" style="">
        <svg
          width="375"
          height="423"
          viewBox="0 0 375 423"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_dddddd_22_9074)">
            <path
              d="M174.887 5.21688C182.622 0.751062 192.152 0.751059 199.887 5.21688L282.274 52.7831C290.009 57.2489 294.774 65.5021 294.774 74.4338V169.566C294.774 178.498 290.009 186.751 282.274 191.217L199.887 238.783C192.152 243.249 182.622 243.249 174.887 238.783L92.5 191.217C84.765 186.751 80 178.498 80 169.566V74.4338C80 65.5021 84.765 57.2489 92.5 52.7831L174.887 5.21688Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_dddddd_22_9074"
              x="0"
              y="0.57138"
              width="374.774"
              height="421.561"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.85185" />
              <feGaussianBlur stdDeviation="1.57407" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_22_9074"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8.14815" />
              <feGaussianBlur stdDeviation="3.25926" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0117778 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_22_9074"
                result="effect2_dropShadow_22_9074"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="20" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.015 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_22_9074"
                result="effect3_dropShadow_22_9074"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="38.5185" />
              <feGaussianBlur stdDeviation="12.7407" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0182222 0"
              />
              <feBlend
                mode="normal"
                in2="effect3_dropShadow_22_9074"
                result="effect4_dropShadow_22_9074"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="64.8148" />
              <feGaussianBlur stdDeviation="23.4259" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0227778 0"
              />
              <feBlend
                mode="normal"
                in2="effect4_dropShadow_22_9074"
                result="effect5_dropShadow_22_9074"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="100" />
              <feGaussianBlur stdDeviation="40" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              />
              <feBlend
                mode="normal"
                in2="effect5_dropShadow_22_9074"
                result="effect6_dropShadow_22_9074"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect6_dropShadow_22_9074"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div class="bg3" style="">
        <svg
          width="432"
          height="805"
          viewBox="0 0 432 805"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_dddddd_22_8915)">
            <path
              d="M340.5 5.21688C348.235 0.751055 357.765 0.75106 365.5 5.21688L613.298 148.283C621.033 152.749 625.798 161.002 625.798 169.934V456.066C625.798 464.998 621.033 473.251 613.298 477.717L365.5 620.783C357.765 625.249 348.235 625.249 340.5 620.783L92.702 477.717C84.967 473.251 80.202 464.998 80.202 456.066V169.934C80.202 161.002 84.967 152.749 92.702 148.283L340.5 5.21688Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_dddddd_22_8915"
              x="0.202148"
              y="0.57138"
              width="705.596"
              height="803.561"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.85185" />
              <feGaussianBlur stdDeviation="1.57407" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_22_8915"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="8.14815" />
              <feGaussianBlur stdDeviation="3.25926" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0117778 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_22_8915"
                result="effect2_dropShadow_22_8915"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="20" />
              <feGaussianBlur stdDeviation="6.5" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.015 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_22_8915"
                result="effect3_dropShadow_22_8915"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="38.5185" />
              <feGaussianBlur stdDeviation="12.7407" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0182222 0"
              />
              <feBlend
                mode="normal"
                in2="effect3_dropShadow_22_8915"
                result="effect4_dropShadow_22_8915"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="64.8148" />
              <feGaussianBlur stdDeviation="23.4259" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0227778 0"
              />
              <feBlend
                mode="normal"
                in2="effect4_dropShadow_22_8915"
                result="effect5_dropShadow_22_8915"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="100" />
              <feGaussianBlur stdDeviation="40" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
              />
              <feBlend
                mode="normal"
                in2="effect5_dropShadow_22_8915"
                result="effect6_dropShadow_22_8915"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect6_dropShadow_22_8915"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div class="ingredient">
        <div class="content">
          <div class="left">
            <div class="img">
<!--              <img src="@/assets/about/ingredient.jpg" alt="" />-->
            </div>
          </div>
          <div class="right">
            <div class="title">FREE mega database with 112K+ ingredients</div>
            <div class="sub-title">
              Reach out to your targets with our selected, optimized, and
              categorized object list.
            </div>
            <div class="items">
              <div class="item">
                <div class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#FDBC64" />
                    <path
                      d="M8 12L10.8 15L16 9"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="word">
                  The ultimate ingredients sourcing solution
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#FDBC64" />
                    <path
                      d="M8 12L10.8 15L16 9"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="word">
                  A super easy tool with the exact results you need
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#FDBC64" />
                    <path
                      d="M8 12L10.8 15L16 9"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="word">
                  Direct connection with Connexima online storefronts
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="choose">
      <div class="sc-width choose-sc">
        <div class="left">
          <div class="title1">Why Choose Us</div>
          <div class="title">
            <div class="item">Easy Access.</div>
            <div class="item">Personalized Filter.</div>
            <div class="item">Accurate Results.</div>
          </div>
          <div class="sub-title">
            Connexima is a transformative platform designed for the future. We
            are changing the nutraceutical industry, one connection at a time.
          </div>
        </div>
        <div class="bg5">
          <svg
            width="229"
            height="256"
            viewBox="0 0 229 256"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.1" filter="url(#filter0_dddddd_22_8931)">
              <path
                d="M107 3.33013C111.641 0.650634 117.359 0.650634 122 3.33013L141.208 14.4199C145.849 17.0994 148.708 22.0513 148.708 27.4103V49.5897C148.708 54.9487 145.849 59.9006 141.208 62.5801L122 73.6699C117.359 76.3494 111.641 76.3494 107 73.6699L87.792 62.5801C83.151 59.9006 80.292 54.9487 80.292 49.5897V27.4103C80.292 22.0513 83.151 17.0994 87.792 14.4199L107 3.33013Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_dddddd_22_8931"
                x="0.291992"
                y="0.0240163"
                width="228.416"
                height="255.656"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.85185" />
                <feGaussianBlur stdDeviation="1.57407" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_22_8931"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="8.14815" />
                <feGaussianBlur stdDeviation="3.25926" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0117778 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_22_8931"
                  result="effect2_dropShadow_22_8931"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="20" />
                <feGaussianBlur stdDeviation="6.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.015 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_dropShadow_22_8931"
                  result="effect3_dropShadow_22_8931"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="38.5185" />
                <feGaussianBlur stdDeviation="12.7407" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0182222 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect3_dropShadow_22_8931"
                  result="effect4_dropShadow_22_8931"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="64.8148" />
                <feGaussianBlur stdDeviation="23.4259" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0227778 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect4_dropShadow_22_8931"
                  result="effect5_dropShadow_22_8931"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="100" />
                <feGaussianBlur stdDeviation="40" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect5_dropShadow_22_8931"
                  result="effect6_dropShadow_22_8931"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect6_dropShadow_22_8931"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div class="right">
          <div class="email">
            <input
              type="text"
              placeholder="Email Address"
              autocomplete="off"
              name="email1"
              v-model="email"
            />
          </div>
          <div class="password">
            <input
              type="password"
              placeholder="Password"
              name="password1"
              autocomplete="new-password"
              v-model="password"
            />
          </div>
          <div class="started search-button" @click="showLogin">
            GET STARTED
          </div>
        </div>
        <div class="bg6">
          <svg
            width="290"
            height="428"
            viewBox="0 0 290 428"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.1" filter="url(#filter0_dddddd_22_8929)">
              <path
                d="M178 5.21688C185.735 0.751057 195.265 0.751059 203 5.21688L287.552 54.0331C295.287 58.4989 300.052 66.7521 300.052 75.6838V173.316C300.052 182.248 295.287 190.501 287.552 194.967L203 243.783C195.265 248.249 185.735 248.249 178 243.783L93.4478 194.967C85.7128 190.501 80.9478 182.248 80.9478 173.316V75.6838C80.9478 66.7521 85.7128 58.4989 93.4478 54.0331L178 5.21688Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_dddddd_22_8929"
                x="0.947754"
                y="0.57138"
                width="379.104"
                height="426.561"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.85185" />
                <feGaussianBlur stdDeviation="1.57407" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_22_8929"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="8.14815" />
                <feGaussianBlur stdDeviation="3.25926" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0117778 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_22_8929"
                  result="effect2_dropShadow_22_8929"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="20" />
                <feGaussianBlur stdDeviation="6.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.015 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_dropShadow_22_8929"
                  result="effect3_dropShadow_22_8929"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="38.5185" />
                <feGaussianBlur stdDeviation="12.7407" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0182222 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect3_dropShadow_22_8929"
                  result="effect4_dropShadow_22_8929"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="64.8148" />
                <feGaussianBlur stdDeviation="23.4259" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0227778 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect4_dropShadow_22_8929"
                  result="effect5_dropShadow_22_8929"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="100" />
                <feGaussianBlur stdDeviation="40" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect5_dropShadow_22_8929"
                  result="effect6_dropShadow_22_8929"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect6_dropShadow_22_8929"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <PopLogin ref="popLogin"></PopLogin>
  </div>
</template>

<script>
import PopLogin from "@/components/popLogin";

export default {
  name: "about",
  computed: {},
  data() {
    return {
      email: "",
      password: ""
    };
  },
  created() {},
  components: { PopLogin },

  methods: {
    showLogin() {
      this.$refs.popLogin.showLogin = true;
      this.$refs.popLogin.loginForm.email = this.email;
      this.$refs.popLogin.loginForm.password = this.password;
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  margin: 150px auto 0 auto;
}
.bg {
  position: absolute;
  top: 153px;
  right: 180px;
}
.top {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-top: 50px;
  .left {
    margin-left: 50px;
    .bg2 {
      position: relative;
      border-radius: 25px;
      height: 0;
      top: 300px;
      opacity: 0.4;
    }
    .title {
      color: #000;
      font-weight: bold;
      font-size: 50px;
    }
    .sub-title {
      font-size: 20px;
      color: #64607d;
      margin: 30px 0 30px 0;
    }
    .btn {
      background: #5243c2;
      border-radius: 47px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 60px;
      font-size: 16px;
    }
  }
  .right {
    margin-right: 200px;
    .title {
      font-weight: bold;
      font-size: 24px;
      color: #3734a9;
    }
    .email {
      margin: 25px 0 25px 0;
      input {
        border-radius: 5px;
        height: 60px;
        width: 356px;
        outline: none;
        padding-left: 10px;
        border: none;
      }
    }
    .password {
      input {
        border-radius: 5px;
        height: 60px;
        width: 356px;
        outline: none;
        padding-left: 10px;
        border: none;
      }
    }
    .started {
      background: #ff7f5c;
      height: 60px;
      width: 356px;
      color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 30px;
    }
  }
}
.we {
  .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height, or 120% */

    letter-spacing: -0.03em;

    color: #000000;
    margin: 100px auto 40px auto;
  }
  .content {
    display: flex;
    justify-content: space-between;
    height: 420px;
    .left {
      img {
        width: 649px;
        height: 378px;
      }
      .img-bottom {
        position: relative;
        bottom: 97px;
      }
      .img-right {
        position: relative;
        bottom: 373px;
        left: 609px;
      }
    }
    .right {
      position: relative;
      width: 500px;
      margin-right: 270px;
      .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        margin: 0 auto 0 auto;
      }
      .word {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        letter-spacing: -0.02em;

        color: #757095;
      }
    }
  }
}
.ingredient {
  margin-top: 90px;
  .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height, or 120% */

    letter-spacing: -0.03em;

    color: #000000;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .left {
      img {
        width: 649px;
        height: 378px;
      }
    }
    .right {
      position: relative;
      width: 500px;
      margin-right: 270px;
      .title {
        font-weight: 800;
        font-size: 40px;
        line-height: 60px;
        /* or 125% */

        letter-spacing: -0.03em;

        color: #000000;
      }
      .sub-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        /* or 167% */

        letter-spacing: -0.02em;
        margin: 30px auto 20px auto;
      }
      .items {
        .item {
          display: flex;
          margin-bottom: 15px;
          .icon {
            margin: 0 10px 0 0;
          }
          .word {
            font-weight: 500;
            font-size: 19px;
            line-height: 30px;
            /* or 158% */

            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}
.choose {
  background: #5243c2;
  .choose-sc {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    /*padding-bottom: 30px;*/
  }
  .left {
    margin: 60px 0 0 0;
    width: 590px;
    .title1 {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;

      color: #ffffff;
      margin-bottom: 20px;
    }
    .title {
      margin-bottom: 20px;
      .item {
        font-weight: 800;
        font-size: 36px;
        line-height: 60px;
        /* or 125% */

        letter-spacing: -0.03em;

        color: #ffffff;
      }
    }
    .sub-title {
      /*font-weight: 600;*/
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      letter-spacing: -0.02em;
      color: #fff;
    }
  }
  .right {
    margin-right: 100px;
    .email {
      margin-top: 60px;
      margin-bottom: 20px;
      input {
        background: #fff;
        opacity: 0.2;
        border-radius: 5px;
        width: 371px;
        height: 60px;
        padding-left: 10px;
        font-size: 18px;
        line-height: 30px;
        /* identical to box height, or 150% */

        letter-spacing: -0.02em;

        color: #000;
        border: none;
      }
    }
    .password {
      margin-bottom: 20px;
      input {
        background: #fff;
        opacity: 0.2;
        border-radius: 5px;
        width: 371px;
        height: 60px;
        padding-left: 10px;
        font-size: 18px;
        line-height: 30px;
        /* identical to box height, or 150% */

        letter-spacing: -0.02em;

        color: #000;
        border: none;
      }
    }
    .started {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ff7f5c;
      border-radius: 5px;
      width: 371px;
      height: 60px;
      color: #fff;
    }
  }
}
.companies {
  .title {
    font-weight: 800;
    font-size: 42px;
    line-height: 60px;
    /* or 143% */

    letter-spacing: -0.03em;

    color: #000000;
    text-align: center;
    position: relative;
    margin: 40px auto 15px auto;
  }
  .sub-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 40px;

    color: #64607d;
  }
  .items {
    display: flex;
    justify-content: center;
    .item {
      width: 400px;
      position: relative;
      .name {
        height: 35px;
        .word {
          font-weight: 800;
          font-size: 16px;
          line-height: 30px;
          /* or 188% */

          letter-spacing: -0.03em;

          color: #000000;
        }
        .pic {
          position: relative;
          font-weight: 700;
          font-size: 204px;
          line-height: 204px;
          /* identical to box height, or 100% */

          letter-spacing: -0.02em;

          color: #000000;

          opacity: 0.05;
          bottom: 140px;
          left: 170px;
        }
      }
      .content {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */

        letter-spacing: -0.02em;

        color: #757095;
        max-width: 280px;
      }
    }
  }
  .button {
    background: #5243c2;
    border-radius: 10px;
    width: 260px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    margin: 50px auto 0 auto;
  }
}
.business {
  position: relative;
  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height */

    text-align: center;

    color: #000000;
    text-align: center;
    margin: 100px auto 45px auto;
  }
  .items {
    display: flex;
    justify-content: center;
    .item {
      width: 200px;
      img {
        height: 100px;
        width: 100px;
      }
    }
  }
}
.bg1 {
  position: relative;
  border-radius: 25px;
  height: 0;
  bottom: 110px;
  opacity: 0.4;
}
.bg3 {
  position: relative;
  border-radius: 25px;
  height: 0;
  bottom: 250px;
  opacity: 0.4;
  float: right;
}
.bg6 {
  position: relative;
  width: 0;
  right: 130px;
  bottom: 106px;
}
.bg5 {
  position: relative;
  width: 0;
  right: 130px;
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .top {
    .right {
      margin-right: 140px;
    }
  }
  .we {
    .content {
      .left {
        .img-bottom {
          bottom: 93px;
          svg {
            width: 580px;
          }
        }
        img {
          width: 580px;
          height: 345px;
        }
      }
      .right {
        margin-right: 75px;
      }
    }
  }
  .we .content .left .img-right {
    left: 540px;
  }
  .companies .items .item {
    width: 370px;
  }
  .ingredient .content .left img {
    width: 570px;
    height: 340px;
  }
  .ingredient .content .right {
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
  }
  .choose .right {
    margin-right: 150px;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .bg {
    right: 80px;
  }
  .top {
    .right {
      margin-right: 140px;
    }
  }
  .we {
    .content {
      .left {
        .img-bottom {
          bottom: 89px;
          svg {
            width: 530px;
          }
        }
        img {
          width: 530px;
          height: 315px;
        }
      }
      .right {
        margin-right: 0;
      }
    }
  }
  .we .content {
    height: 380px;
  }
  .we .content .left .img-right {
    left: 495px;
  }
  .companies .items .item {
    width: 370px;
  }
  .ingredient .content .left img {
    width: 525px;
    height: 314px;
  }
  .ingredient .content .right {
    position: relative;
    margin-left: 30px;
    margin-right: 0px;
  }
  .choose .right {
    margin-right: 20px;
  }
}
@media (max-width: 1200px) {
  .bg {
    right: 80px;
  }
  .top {
    .right {
      margin-right: 140px;
    }
  }
  .we {
    .content {
      .left {
        .img-bottom {
          bottom: 89px;
          svg {
            width: 530px;
          }
        }
        img {
          width: 530px;
          height: 315px;
        }
      }
      .right {
        margin-right: 0;
      }
    }
  }
  .we .content {
    height: 380px;
  }
  .we .content .left .img-right {
    left: 495px;
  }
  .companies .items .item {
    width: 370px;
  }
  .ingredient .content .left img {
    width: 525px;
    height: 314px;
  }
  .ingredient .content .right {
    position: relative;
    margin-left: 30px;
    margin-right: 0px;
  }
  .choose .right {
    margin-right: 20px;
  }
}
</style>
